import React, { useEffect } from "react";
import { Link } from "gatsby";
import { Wrapper } from "../components/site/Wrapper";
import { Grid, Card, Image, Text, Badge, Button, Group } from "@mantine/core";
import { useNavigate } from "@gatsbyjs/reach-router";
import puzzle from "../images/puzzle.png";
import ShortQR from "../components/ShortQR";

const Puzzle = ({ data }) => {
  const nav = useNavigate();
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 py-3">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{data.title}</h5>
          <div className="card-text my-3"></div>
          <Link
            to={`./${data.urlEncodedTitle.toLowerCase()}`}
            className={`btn btn-primary text-white`}
          >
            Play Puzzle
          </Link>
        </div>
      </div>
    </div>
  );
};

export const Category = ({ pageContext, limit, ...props }) => {
  useEffect(() => {
    console.log("props", pageContext, props);
  }, []);
  const { title, puzzles = [] } = pageContext;

  console.log(puzzles, pageContext);

  return (
    <div>
      <h1>{pageContext.node.name}</h1>
      <div>
        <div>
          <div className="container">
            <div className="row">
            {[...puzzles.slice(0, limit || puzzles.length)]
              .sort((puzzle1, puzzle2) => {
                return puzzle2.title > puzzle1.title ? -1 : 1;
              })
              .map((puzzle) => (
                <Puzzle data={puzzle} />
              ))}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

const ComponentWrapper = ({ pageContext, ...props }) => {
  return (
    <Wrapper current={pageContext.node.name}>
      <Category pageContext={pageContext} {...props} />
    </Wrapper>
  );
};

export default ComponentWrapper;
